import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BlobUploadCommonResponse, BlockBlobClient } from '@azure/storage-blob'
import moment from 'moment'
import { BehaviorSubject, Observable, of, from, combineLatest } from 'rxjs'
import {
  delay,
  filter as rxFilter,
  map,
  mergeMap,
  repeat,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators'
import * as fromAppStatus from 'src/app/app-status/app-status.state'
import * as fromAppStatusSelectors from 'src/app/app-status/selectors/app-status.selectors'
import { DateFormatEnum } from 'src/app/enums/date.enums'
import { TransactionStateEnum } from 'src/app/enums/transaction.enums'
import { IMerchantStore } from 'src/app/merchant-store/models/imerchant-store.model'

import { IApiSalesTotal } from '../models/iapi-sales-total'
import { IServiceResult, IServiceResultTransaction } from '../models/iapi-transactions.models'
import { IApiUpload } from '../models/iapi-uploads.models'
import { ISalesTotal } from '../models/isales-total'
import { ITransactionDetail } from '../models/itransaction-detail.model'
import { ITransactionFilter } from '../models/itransaction-filter.model'
import { ITransactionResult } from '../models/itransaction-result.model'
import { ITransactionUpdate } from '../models/itransaction-update'
import { ITransaction } from '../models/itransaction.model'
import { IDocumentUploadConnection, IUpload } from '../models/iupload.models'
import { TransactionAdapter } from '../utilities/transaction-adapter.utilities'
import { UploadsAdapter } from '../utilities/uploads-adapter.utilites'
import * as transactionActions from './../actions/transaction.actions'
import * as fromTransaction from './../transaction.store'
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  runningUploads$ = new BehaviorSubject<IUpload[]>(null)
  complete$ = this.runningUploads$.pipe(rxFilter((item) => item && item.length === 0))

  constructor(
    private http: HttpClient,
    private transactionStore: Store<fromTransaction.State>,
    private appStatus: Store<fromAppStatus.State>
  ) {}

  public getTransactions(
    filter: ITransactionFilter = {},
    page: number = 1,
    pageLimit: number = 250
  ): Observable<ITransactionResult> {
    return this.getTransactionsFromApiWhenOnline(filter, page, pageLimit)
  }

  public getLatestTransactions(pageLimit: number = 250): Observable<ITransaction[]> {
    return this.http
      .post<IServiceResultTransaction[]>(
        'api/transactionsrecent',
        {},
        {
          headers: new HttpHeaders({ ContentType: 'application/json' }),
          params: new HttpParams({
            fromObject: {
              pagesize: pageLimit.toString(),
            },
          }),
        }
      )
      .pipe(
        map((results) => {
          return results.map((result) =>
            TransactionAdapter.mapApiTransactionsToTransactions(result)
          )
        })
      )
  }

  private getTransactionsFromApiWhenOnline(
    filter: ITransactionFilter,
    page: number,
    pageLimit: number
  ): Observable<ITransactionResult> {
    return this.appStatus.select(fromAppStatusSelectors.selectOnline).pipe(
      rxFilter((online) => online),
      switchMap(() => this.getTransactionsFromApi(filter, page, pageLimit))
    )
  }

  public getTransactionsFromApi(
    filter: ITransactionFilter,
    page: number,
    pageLimit: number
  ): Observable<ITransactionResult> {
    return this.http
      .get('api/transactionsquery', {
        headers: new HttpHeaders({ ContentType: 'application/json' }),
        params: new HttpParams({
          fromObject: {
            ...TransactionAdapter.mapFilterToApiFilter(filter, page, pageLimit),
          },
        }),
      })
      .pipe(
        map((result: IServiceResult): ITransactionResult => {
          return {
            filingIds: result.filingIdsInfo,
            message: result.searchMessage,
            transactions: result.transactions.map((transaction) =>
              TransactionAdapter.mapApiTransactionsToTransactions(transaction)
            ),
            complete: result.transactions.length < pageLimit,
          }
        })
      )
  }

  public getDownloadTransactions(
    filter: ITransactionFilter = {},
    isSummary: boolean,
    isConsolidated = false
  ): Observable<[string, Blob]> {
    return this.http
      .post(
        'api/transactions/downloadQuery',
        {},
        {
          observe: 'response',
          responseType: 'blob',
          headers: new HttpHeaders({ ContentType: 'application/json' }),
          params: new HttpParams({
            fromObject: {
              ...TransactionAdapter.mapFilterToDownloadApiFilter(filter, isSummary, isConsolidated),
            },
          }),
        }
      )
      .pipe(map((response) => [this.getFileNameFromHttpResponse(response), response.body]))
  }

  public getConsolidatedDownloadTransactionsForFilings(
    filingIds: string,
    isSummary: boolean
  ): Observable<[string, Blob]> {
    return this.http
      .post(
        'api/transactions/consolidated/downloadForFilings',
        {},
        {
          observe: 'response',
          responseType: 'blob',
          headers: new HttpHeaders({ ContentType: 'application/json' }),
          params: new HttpParams({
            fromObject: {
              filingIds,
              showLineItems: (!isSummary).toString(),
            },
          }),
        }
      )
      .pipe(map((response) => [this.getFileNameFromHttpResponse(response), response.body]))
  }

  public getTransaction(transactionId: string | number): Observable<ITransactionDetail> {
    return this.http.post(`api/transaction/${transactionId.toString()}`, {}).pipe(
      map((results) => {
        if (!results) {
          throw new Error('Missing Transaction')
        }
        return TransactionAdapter.mapApiTransactionDetailToTransationDetail(results)
      })
    )
  }

  public returnTransaction(
    transactionId: string,
    returnedDate: string
  ): Observable<[string, ITransactionUpdate]> {
    const body = {
      transactionid: transactionId,
      returnedDate,
    }
    return this.http.post(`api/transactions/ReturnTransaction`, body).pipe(
      map((response: string) =>
        TransactionAdapter.mapTransactionUpdateResponse('success', TransactionStateEnum.Returned)
      ),
      map((result) => [transactionId, result])
    )
  }

  public captureTransaction(
    transactionId: string,
    cartId: string,
    useLookupDate: boolean
  ): Observable<[string, ITransactionUpdate]> {
    const body = {
      transactionid: transactionId,
      CartID: cartId,
      UseTransactionDate: useLookupDate ? 1 : 0,
    }
    return this.http.post(`api/transactions/capture`, body).pipe(
      map((response: string) =>
        TransactionAdapter.mapTransactionUpdateResponse(response, TransactionStateEnum.Caputured)
      ),
      map((result) => [transactionId, result])
    )
  }

  public getLastUpdated(): Observable<string> {
    return this.http
      .get(`api/transactions/LastUpdatedUI`)
      .pipe(map((utc: string) => TransactionAdapter.mapUtcTimeToLocalTime(utc)))
  }

  public getLatestTotals(): Observable<ISalesTotal> {
    return this.getSalesTotalByMonth(moment().startOf('month').format(DateFormatEnum.Month))
  }

  public getSalesTotalByMonth(month: string): Observable<ISalesTotal> {
    const period = `${month}`
    return this.http
      .get(`api/dashboard2/salestotals2`, { params: new HttpParams({ fromObject: { period } }) })
      .pipe(
        map((result: IApiSalesTotal) => {
          result.result.period = period
          return result
        }),
        map((result: IApiSalesTotal) => TransactionAdapter.mapApiSalesTotalToSalesTotal(result))
      )
  }

  public importTransactionsFromLinkedStore(store: IMerchantStore) {
    if (!store.isLinked) {
      throw new Error('Not a linked store')
    }
    return this.http.post(
      `api/marketplace/ManualStoreUpload`,
      {},
      { params: new HttpParams({ fromObject: { urlid: store.id } }) }
    )
  }

  public getUploads(dateStart: number = 20190301) {
    return this.http
      .get(`api/transactionupload/?status=completed&mindate=${dateStart}`)
      .pipe(
        map((uploads: IApiUpload[]) =>
          uploads
            .map((upload) => UploadsAdapter.mapApiUploadToUpload(upload))
            .sort((a, b) =>
              a.creationTime > b.creationTime ? -1 : b.creationTime > a.creationTime ? 1 : 0
            )
        )
      )
  }

  public watchRunningUploads() {
    return this.runningUploads$.pipe(rxFilter((uploads) => !!uploads))
  }

  public pollforRunningUploads(): Observable<IUpload[]> {
    this.runningUploads$.next(null)
    const poll = of({}).pipe(
      mergeMap((_) => this.getRunningUploads()),
      takeUntil(this.complete$),
      delay(5000),
      repeat()
    )

    return poll
  }

  private getRunningUploads(): Observable<IUpload[]> {
    return this.http.get(`api/transactionupload/?status=running&mindate=2019-04-29`).pipe(
      map((uploads: IApiUpload[]) =>
        uploads
          .map((upload) => UploadsAdapter.mapApiUploadToUpload(upload))
          .sort((a, b) =>
            a.creationTime > b.creationTime ? -1 : b.creationTime > a.creationTime ? 1 : 0
          )
      ),
      tap((uploads) => {
        if (uploads.length === 0) {
          this.runningUploads$.next(uploads)
        } else {
          this.runningUploads$.next(uploads)
        }
      })
    )
  }

  private uploadCsvToAzure(sasURL: string, file: File): Observable<BlobUploadCommonResponse> {
    const blockBlobClient = new BlockBlobClient(sasURL)
    return from(blockBlobClient.uploadData(file, { blockSize: 4 * 1024 * 1024, concurrency: 20 }))
  }

  public uploadCsv(file: File, id: string, v1API = true): Observable<any> {
    if (v1API) {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('urlid', id.toString())
      return this.http.post(`api/transactionupload`, formData)
    } else {
      return this.http
        .get(
          `${environment.v3ApiBaseUrl}/core/connections/${id}/document-connection/${file.name}?extraFileNameDetails=order`
        )
        .pipe(
          switchMap((response: IDocumentUploadConnection) =>
            combineLatest([of(response), this.uploadCsvToAzure(response.url, file)])
          ),
          map(([response, _]) => response)
        )
    }
  }

  public clearTransactions(): void {
    this.transactionStore.dispatch(new transactionActions.ClearTransactions())
  }

  private getFileNameFromHttpResponse(httpResponse: HttpResponse<Blob>): string {
    const dispositionHeader = 'Content-Disposition'

    if (!httpResponse.headers.has(dispositionHeader)) {
      return 'data'
    }

    const fileNameHeader = httpResponse.headers
      .get(dispositionHeader)
      .split(';')
      .map((header) => header.trim().split('='))
      .find((item) => item[0] === 'filename')

    if (!fileNameHeader) {
      return 'data'
    }

    return fileNameHeader[1] || 'data'
  }
}
