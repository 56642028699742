<div class="display black">
  <div *ngIf="currentDate | async as currentDate">{{ currentDate | date: 'MMM yyyy' }}</div>
  <div *ngIf="(currentDate | async) === null">Nothing Selected</div>
  <button mat-icon-button matSuffix [matMenuTriggerFor]="appMenu">
    <i class="pi pi-calendar" style="font-size: 1.25rem"></i>
  </button>
</div>

<mat-menu #appMenu="matMenu" yPosition="below" role="menuitemradio" [class]="'button-wrapper'">
  <div>
    <div class="year">
      <button mat-icon-button matSuffix (click)="decrementYear($event)">
        <mat-icon>arrow_left</mat-icon>
      </button>
      <div>{{ year }}</div>
      <button mat-icon-button matSuffix (click)="incrementYear($event)">
        <mat-icon>arrow_right</mat-icon>
      </button>
    </div>
    <mat-button-toggle-group
      name="month"
      aria-label="Month"
      [value]="selectedMonth$ | async"
      (change)="chosenMonthHandler($event)"
    >
      <mat-grid-list cols="4" rowHeight="1:1.2">
        <mat-grid-tile *ngFor="let month of months | async">
          <mat-button-toggle
            class="month mat-button-toggle"
            [disabled]="month.disabled"
            [value]="month.value"
          >
            {{ month.label }}
          </mat-button-toggle>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-button-toggle-group>
  </div>
</mat-menu>
